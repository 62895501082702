<template>
    <CRow>
      <CCol sm="12" class="min-container">
        <CCard>
          <CCardBody>
            <CCol sm="12" lg="12" class="d-flex align-items-center" style="background-color: #5d6164;">
              <CButton
                color="edit"
                class="mr-2"
                v-c-tooltip="{
                  content: $t('label.return'),
                  placement: 'top',
                }"
                size="sm"
                @click="closeCollapse"
              >
                <CIcon name="cil-chevron-left-alt" />
              </CButton>
            </CCol>  
            <CRow align-horizontal="center">
              <CCol sm="12">
                  <transition name="fade">
                    <div class="form-step" v-if="positions.length>0"> 
                      <transition name="slide-fade">
                        <div v-if="currentStep == 0">
                          <bay-tier-rows  @generated="handleGenerated"/>
                        </div>
                        <div v-if="currentStep == 1">
                          <bay-tier-rows  @generated="handleGenerated"/>
                        </div>
                        <div v-if="currentStep == 2">
                          <bay-tier-rows @generated="handleGenerated"/>
                        </div>
                      </transition>
                    </div>
                  </transition>
                </CCol>
            </CRow>
            <form-controls
                :steps="maxSteps"
                :current="currentStep"
                :preText="preBtnTexts"
                :nextText="nextBtnTexts"
                @step-action="stepAction"
              />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>    
  </template>
  
<script>
import { mapState } from "vuex";
import BayTierRows from './tier-rows';
import { BayMixin } from '@/_mixins/buque';
import FormControls from './form-controls';
  
function data() {
  return {
    maxSteps: 3,
  }
}

//Methods:
function stepAction(num) {
  switch (num) {
    case 0:
      this.$store.commit('yard/nextStep');
      break;
    case 1:
      if(Array.isArray(this.positions) && this.positions.length != 0) {
        this.updatePositions();
      } else {
        this.$store.commit('yard/nextStep');
      }
      break;
    case 2:
      this.$store.commit("yard/changePositions", { item: []});
    default:
      break;
  }
}
function updatePositions() {
  this.$store.state.yard.YardHeightId;
  this.$store.state.yard.YardBlockId;
  let slotJson = [];

  this.positions.map(function(item){
    item.SlotJson.map(function(item){
      slotJson.push({
        YardSlotId: item.YardSlotId,
        YardSlotStatus: item.YardSlotStatusId ?? '',
      })
    })
  })
  
  let json = {
    HeightJson:[
      {
        YardHeightId: this.$store.state.yard.YardHeightId, 
        BlockJson:[
              {   YardBlockId: this.$store.state.yard.YardBlockId, 
                  SlotJson: slotJson
              }
          ] 
      }
    ]
  }

  this.$store.dispatch('yard/updatePositions', json)
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response[0].Response,
      type: "success"
    });
    this.$store.commit('yard/nextStep');
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
  });
}

function handleGenerated(type) {

}

//Computerd: 

function preBtnTexts() {
  return [
    this.$t('label.blocksBays'),
    this.$t('label.conditions'),
  ];
}
function nextBtnTexts() {
  return [
    this.$t('label.conditions'),
    this.$t('label.generatoPlan'),
    this.$t('label.exit')
  ];
}

function tableText(){
  return tableTextTranslatedHelpers(this);
}

function closeCollapse(){
  this.$store.commit("yard/changePositions", { item: []});
}

export default {
  name: 'bay-plan-view',
  components: {
    BayTierRows,
    FormControls
  },
  mixins: [BayMixin],
  props: {active: Number, default: 0, titleHeight: String},
  data,
  methods: {
    stepAction,
    handleGenerated,
    updatePositions,
    closeCollapse,
  },
  computed: {
    preBtnTexts,
    nextBtnTexts,
    tableText,
      ...mapState({
        collapseHeightPlan: state => state.yard.collapseHeightPlan,
        currentStep: state => state.yard.current,
        positions: state => state.yard.positions,
      }),
  }

}
</script>

<style scoped>
.min-container {
  min-height: 390px;
}
.dash{
  border: 0 none;
  border-top: 1px dashed #322f32;
  background: none;
  height:0;
  width: 80%;
  margin: 10px auto;
}
</style>